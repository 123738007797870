<template>
  <div class="w-full">
    <div class="relative items-center w-full"
         :class="type === 'small' ? '414px:flex' : '2xs:flex'"
    >
      <label for="email"
             class="sr-only"
      >{{ label }}</label>
      <input required
             tabindex="1"
             :disabled="state === 'loading'"
             :value="modelValue"
             @input="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
             id="email"
             name="email"
             type="email"
             class="w-full py-4 pl-6 text-base font-medium text-white placeholder-gray-200 bg-gray-300 border-none rounded-full sm:pr-40 focus:outline-none focus:ring-blue-300 focus:ring-2 focus:ring-offset-black disabled:cursor-wait"
             :placeholder="placeholder"
             :class="state === 'error' ? 'ring ring-red-200' : 'ring-0'"
      >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FormStateType } from '../../../types/form';

interface FormInputProps {
  state: FormStateType;
  placeholder: string;
  label: string;
  modelValue: string;
  type?: string;
}

defineProps<FormInputProps>();

const emit = defineEmits<{
  (event: 'update:modelValue', payload: string): void;
}>();
</script>
